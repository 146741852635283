<template lang="pug">
.container
  b-card.border-0
    p.h5 Model
    .d-inline.mr-3(v-for="(option, index) in radio.options")
      input(
        type="radio"
        :value="option"
        v-model="radio.picked"
        :id="`${option}_${index}`"
      )
      label.m-0.h6(:for="`${option}_${index}`") {{ option }}
    .d-flex.justify-content-center
      h1 {{ profile.name }}
    full-calendar(ref="calendar" :options="calendarOptions")
      template(v-slot:eventContent="arg")
        .d-flex.justify-content-center {{ arg.event.title }}
    .d-flex.justify-content-between.align-items-center.mt-3
      div
        p.h5 Model
        .d-inline.mr-3(v-for="(option, index) in radio.options")
          input(
            type="radio"
            :value="option"
            v-model="radio.picked"
            :id="`${option}_${index}`"
          )
          label.m-0.h6(:for="`${option}_${index}`") {{ option }}
      div
        b-button Submit
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import TimeGridPlugin from '@fullcalendar/timegrid';

import WuwowLoadingMixin from '@/components/wuwowLoading/mixin/index.vue';

import { uniqueId } from 'lodash';
import moment from 'moment';

import {
  getColorAndTitle,
  getRestTime,
  isTimeRangeInRestTime,
  isPastOrCurrent,
  STATUS,
} from '@/utils/schedule';
import api from '@lioshutan/api-package';
import storage from '@/utils/localStorage';

const DISPLAY = 'Display';
const DELETE = 'Delete';
const ADD = 'Add';

export default {

  components: {
    FullCalendar,
  },

  mixins: [WuwowLoadingMixin],

  data() {
    return {
      profile: {
        name: '',
      },
      radio: {
        picked: DISPLAY,
        options: [
          DISPLAY,
          DELETE,
          ADD,
        ],
      },
      target: null,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          interactionPlugin,
          TimeGridPlugin,
        ],
        allDaySlot: false,
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,today',
        },
        selectOverlap: false,
        initialView: 'timeGridWeek',
        // locale: 'tw',
        selectable: true,
        select: this.handleDateSelect,
        eventAdd: this.eventAdd,

        events: this.handleEvents,
        eventClick: this.handleEventClick,
        // eventsSet: this.handleEvents,
        eventChange: this.eventChange,
        eventRemove: this.eventRemove,
        /* you can update a remote database when these fire:
        */
      },
      currentEvents: [],
    };
  },
  async created() {
    const tag = this.startLoading();
    const response = await api
      .setDNS(process.env.VUE_APP_BASE_API)
      .consultant(storage.token)
      .getCenterConsultants();
    this.profile.name = response.find(
      (data) => data.hbConsultantId === +this.$route.params['id']
    ).englishName;
    this.endLoading(tag);
  },
  mounted() {
    // if (this.$route.query['date']) {
    //   this.$refs['calendar']['getApi']()['gotoDate'](this.$route.query['date']);
    // }
  },
  methods: {
    /**
     * 抓取所有行程
     */
    async handleEvents({ start, end }, success, fail) {
      // this.redirectDate(moment(start).format('YYYYMMDD'));

      const tag = this.startLoading();
      const response = await this.getConsultantSchedulePerWeek({
        start,
        end,
      });
      success(response);
      this.endLoading(tag);
    },

    /**
     * 取得顧問排程
     *
     * @returns {Promise<Array>} 顧問排成
     */
    async getConsultantSchedulePerWeek({ start, end }) {
      const sunday = moment(start).format('YYYYMMDD');
      const saturday = moment(end).format('YYYYMMDD');
      this.id = this.$route.params['id'];

      const response = (await api
        .setDNS(process.env.VUE_APP_BASE_API)
        .consultant(storage.token)
        .getSchedule(
          this.id,
          `${sunday}01`, `${saturday}31`
        ));

      const result = response.map(({
        id,
        status,
        timeslot,
        timestamp,
        type,
      }) => {
        const start = moment(
          `${timestamp.toString().substring(0, 8)}0830`,
          'YYYY-MM-DD HH:mm'
        ).add(timeslot * 30, 'minute');
        const end = moment(start.format('YYYY-MM-DD HH:mm')).add(30, 'minute');

        const result = {
          id,
          start: start.toISOString(),
          end: end.toISOString(),
          status,
          type: +type,
        };

        const colorAndTitle = getColorAndTitle(status, +type);

        return {
          backgroundColor: colorAndTitle.color,
          borderColor: colorAndTitle.color,
          title: colorAndTitle.title,
          ...result,
        };
      });

      result.push(...getRestTime(start, end).map((time) => ({
        ...time,
        display: 'background',
        color: '#ff9f89',
      })));
      return result;
    },

    /**
     * 針對未排定的部分動作
     */
    handleDateSelect({ start, end, startStr, endStr, view: { calendar }}) {
      console.log([start, end]);
      if (isTimeRangeInRestTime(start, end)) {
        alert('您無法在休息時間排課！');
        return;
      }

      if (isPastOrCurrent(start)) {
        // alert('You can not add the event into past.');
        alert('該時間段無法排課！');
        return;
      }

      if ([DISPLAY, DELETE].includes(this.radio.picked)) {
        // alert('Please switch to the add model.');
        alert('請選擇新增模式！');
        return;
      }

      const title = prompt('Please enter a new title for your event');
      const calendarApi = calendar;
      // console.log(calendarApi);

      calendarApi.unselect(); // clear date selection
      const id = uniqueId('t');
      console.log(id);

      if (title) {
        calendarApi.addEvent({
          id,
          title,
          start: startStr,
          end: endStr,
          // allDay: selectInfo.allDay,
        });
      }
    },

    /**
     * 針對Event動作
     */
    handleEventClick({
      el,
      event: {
        display,
        extendedProps: {
          status,
          type,
        },
        start,
        end,
      },
      jsEvent,
      view,
    }) {
      console.log([start, end]);
      if (isTimeRangeInRestTime(start, end)) {
        // alert('You can not arrange an event in rest time.');
        alert('您無法在休息時間排課！');
        return;
      }

      if (isPastOrCurrent(start)) {
        // alert('You can not delete the past event.');
        alert('您不允許修改過去的行程！');
        return;
      }

      if ([DISPLAY, ADD].includes(this.radio.picked)) {
        // alert('Please switch to the delete model.');
        alert('請選擇刪除模式！');
        return;
      }

      if ([STATUS.CLASS, STATUS.DEMO, STATUS.PENDING].includes(status)) {
        // alert('You can not change this event status.');
        alert('您不允許修改該行程的狀態！');
        return;
      }

      console.log('test');

      // if (confirm(`Are you sure you want to delete the event '${event.title}'`)) {
      //   event.remove();
      // }
    },

    eventAdd(data) {
      console.log('eventAdd', data);
    },
    eventChange(data) {
      console.log('eventChange', data);
    },
    eventRemove(data) {
      console.log('eventRemove', data);
    },

    handleWeekendsToggle() {
      console.log('week toggle');
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },

    // handleEvents(events) {
    //   // console.log(this.$refs['calendar']);
    //   // console.log(events);
    //   this.currentEvents = events;
    // },
  },
};
</script>
